
























import { Component, Prop, Vue } from 'vue-property-decorator'
import Modal from '@/app/ui/components/Modal/index.vue'
import Button from '@/app/ui/components/Button/index.vue'
import ConfirmationIcon from '@/app/ui/assets/icon_confirmation.vue'

@Component({
  components: {
    Modal,
    Button,
    ConfirmationIcon,
  },
})

export default class ModalUnstarredQuestion extends Vue {
  @Prop({ default: false }) private visible!: boolean
}
