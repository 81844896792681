

































































import { Component, Vue, Watch } from 'vue-property-decorator'
import controller from '@/app/ui/controllers/FAQController'
import { StarQuestion } from '@/domain/entities/FAQ'
import Draggable from 'vuedraggable'
import Button from '@/app/ui/components/Button/index.vue'
import Modal from '@/app/ui/components/Modal/index.vue'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import StarItem from '../components/StarItem/index.vue'
import ModalUnstarredQuestion from '../components/Modals/UnstarredQuestion.vue'
import { EventBus, EventBusConstants } from '@/app/infrastructures/misc'

@Component({
  components: {
    Draggable,
    Button,
    Modal,
    LoadingOverlay,
    StarItem,
    ModalUnstarredQuestion
  },
})
export default class FAQStarredPage extends Vue {
  controller = controller
  questionList = [] as StarQuestion[]
  successModal = {
    show: false,
    title: '',
    body: '',
  }
  unstarQuestionModal = {
    show: false,
    idQuestion: 0
  }

  created(): void {
    EventBus.$on(EventBusConstants.UNSTAR_QUESTION_SUCCESS, () => {
      this.successModal = {
        show: true,
        title: 'Unstar Question Saved',
        body: 'New information has been saved',
      }
    })

    EventBus.$on(EventBusConstants.UPDATE_REORDER_STAR_QUESTION_SUCCESS, () => {
      this.successModal = {
        show: true,
        title: 'Starred Question Saved',
        body: 'New information has been saved',
      }
    })

    this.fetchListQuestion()
  }

  private fetchListQuestion() {
    controller.getListQuestion()
  }

  private onUnstarQuestion() {
    this.controller.starUnstarQuestion({
      idQuestion: this.unstarQuestionModal.idQuestion,
      action: 'unstar'
    })
    this.unstarQuestionModal.show = false
  }

  private onCloseSuccessModal() {
    this.successModal.show = false
    this.fetchListQuestion()
  }

  private async onUploadImage(index: number, file: File) {
    if (file) {
      const image = await controller.postImageCategory(file)
      this.questionList[index].iconImage = image as string
    } else {
      this.questionList[index].iconImage = ''
    }
  }

  private onSaveStarQuestion() {
    const payload = this.questionList.map((item, idx) => {
      return {
        id: item.id,
        starOrder: idx + 1,
        iconImage: item.iconImage
      }
    })
    this.controller.reorderStarQuestion(payload)
  }

  @Watch('controller.starQuestionList')
  onChangeStarQuestionList(val: StarQuestion[]): void {
    this.questionList = val
  }

  beforeDestroy(): void {
    EventBus.$off(EventBusConstants.UNSTAR_QUESTION_SUCCESS)
    EventBus.$off(EventBusConstants.UPDATE_REORDER_STAR_QUESTION_SUCCESS)
  }
}
