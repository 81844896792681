












































import { Component, Prop, Vue } from 'vue-property-decorator'
import { VueEditor } from 'vue2-editor'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import CategoryImage from '../CategoryImage/index.vue'
import CaretDownIcon from '@/app/ui/assets/caret_down_icon.vue'
import DragIcon from '@/app/ui/assets/drag_icon.vue'
import StarIcon from '@/app/ui/assets/icon_star.vue'
import DragDisabledIcon from '@/app/ui/assets/icon_drag_disabled.vue'

@Component({
  inheritAttrs: false,
  components: {
    VueEditor,
    TextInput,
    CategoryImage,
    CaretDownIcon,
    DragIcon,
    StarIcon,
    DragDisabledIcon,
  },
})
export default class StarItem extends Vue {
  @Prop({ required: true }) private index!: number
  @Prop({ required: true }) private order!: number
  @Prop({ required: true }) private question!: string
  @Prop({ required: true }) private answer!: string
  @Prop({ default: '' }) private iconImage!: string
  @Prop({ default: 0 }) private idQuestion!: number
  @Prop({ default: true }) private isStar!: boolean

  private async onFileChange(index: number, file: File) {
    this.$emit('upload-image', index, file)
  }
}
